.text {
  font-weight: 700;
  font-size: 56px;
  line-height: 76px;
  text-transform: uppercase;
  margin: 0 auto;
  padding: 0 12px;
  text-align: center;
}

.gradient {
  text-shadow: 0 2px 2px rgba(7, 9, 17, 20%), 0 24px 40px rgba(12, 19, 33, 0.24), 0 4px 40px rgba(147, 176, 235, 0.62);
  -webkit-text-fill-color: transparent;
  background: var(--main);
  -webkit-background-clip: text;
}