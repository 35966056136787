@import "../../styles/media-mixin";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 416px;
  background: var(--grey-2-24);
  border-radius: 4px;
  padding: 56px 40px;

  @include media("<desktop-xlg") {
    padding: 40px;
    max-width: 356px;
  }

  @include media("<tablet") {
    padding: 24px;
    max-width: unset;
  }

  @include media("<phone-lg") {
    padding: 16px;
  }
}

.title {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: var(--white);
}

.description {
  text-align: center;
  margin-top: 24px;
  color: var(--grey-1);

  @include media("<desktop-xlg") {
    max-width: 176px;
  }

  @include media("<tablet") {
    max-width: unset;
  }

  @include media("<phone-lg") {
    max-width: 176px;
  }
}

.buttonWrapper {
  text-align: center;
  margin: 16px auto 0;
}