.root {
  display: flex;
  align-items: center;
  padding: 20px 0;

  &.left {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }
}

.button {
  color: var(--main);
  font-weight: 400;
}

.line {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--grey-1);
  margin: 0 16px;
}

.title {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--grey-1);
}
