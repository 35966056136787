@import "../../../styles/media-mixin";

.description {
  margin-bottom: 24px;
  max-width: 600px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-2);

  @include media("<phone-lg") {
    font-size: 14px;
    line-height: 22px;
  }
}