.container {
  width: 100%;
  background: var(--grey-3);
  border-bottom: 1px solid var(--dark-6);
  padding: 0 24px 24px;
  max-height: 100%;
  overflow-y: scroll;
}

.burgerNavBar {
  padding: 24px;
}

.actionsWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 0 24px;
}

.burgerActionButton {
  flex: 1;
  justify-content: center;
}

.burgerIcon {
  position: relative;
  width: 60px;
  height: 48px;
  cursor: pointer;
  z-index: 120;
  //border: 1px solid var(--main-dark);
  border-radius: 4px;
  background-color: var(--grey-2);


  .top,
  .middle,
  .bottom {
    position: absolute;
    left: 50%;
    width: 18px;
    height: 2px;
    transform: translateX(-50%);
    border-radius: 1px;
    background-color: var(--bg);
    transition-duration: 0.25s;
    transition-timing-function: ease-in-out;
  }

  .top {
    top: 35%;
    transition-property: transform, top;
  }

  .middle {
    top: 50%;
    transform: translate(-50%, -50%);
    transition-property: opacity;
    opacity: 1;
  }

  .bottom {
    bottom: 35%;
    transition-property: transform, bottom;
  }
}

.burgerIconOpen {

  .top {
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .middle {
    opacity: 0;
  }

  .bottom {
    bottom: 50%;
    transform: translate(-50%, 50%) rotate(-45deg);
  }
}
