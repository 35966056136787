@import "../../../../styles/media-mixin.scss";

.layoutSection {
  background-color: var(--bg);
}

.layoutSectionContent {
  padding-top: var(--global-vertical-padding);
  padding-bottom: calc(var(--global-vertical-padding) * 1.5);
}

.sectionText {
  max-width: 640px;
  margin-top: 24px;
  font-size: 16px;
  line-height: 28px;
  color: var(--grey-2);

}

.cardsContainer {
  margin-top: 40px;
  margin-bottom: 64px;
  display: flex;
  align-items: stretch;
  gap: 16px;

  @include media("<tablet") {
    flex-direction: column;
  }
}

.cardWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  gap: 24px;
  border-radius: 4px;
  background-color: var(--white);
}

.cardText {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: var(--font);
}

.cardButton {
  width: 100%;
}

.withdrawalTermsWrapper {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  border-radius: 4px;
  background-color: var(--white);
}

.withdrawalConditionsTitle {
  margin-bottom: 24px;
}

.withdrawalConditionsText {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--grey-2);
}

