@import "../../styles/media-mixin";

.pageHeaderBlock {
  position: relative;
  padding-bottom: 40px;
  background-size: cover;
  background-repeat: no-repeat;
}

.sectionContentWrapper {
  padding-top: var(--header-height);
}

.pageTitle {
  margin-top: 4px;
  font-weight: 700;
  font-size: 80px;
  line-height: 96px;
  text-align: center;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.4) 12.29%, #FFFFFF 41.21%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  @include media("<tablet") {
    font-size: 56px;
    line-height: 72px;
  }
}

.contentWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 24px 40px;
  background-color: var(--bg);
}

.textItemWrapper {
  position: relative;
  margin-bottom: 24px;
  max-width: 816px;
  z-index: 1;
}

.textItemTitle {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: var(--font);
}

.textItemDescription {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: var(--grey-2);
  margin-bottom: 16px;
}

.textItemList {
  margin-bottom: 40px;
  list-style: none;
}

.textItemListItem {
  display: flex;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: var(--grey-2);
  margin-bottom: 16px;

  &:before {
    content: "•";
    width: 12px;
    margin-right: 12px;
    color: var(--grey-2);
  }
}

.link {
  color: var(--font);
}
