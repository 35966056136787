@import "../../../../styles/media-mixin.scss";

.layoutSectionWrapper {
  background-repeat: no-repeat;
  background-size: 1920px;
  background-position: center;
}

.layoutSectionContent {
  padding-bottom: 64px;
  padding-top: var(--header-height);
}

.pageTitle {
  margin-top: 20px;
  color: var(--white);
}

.actionsWrapper {
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.button {
  width: 160px;
  //color: var(--grey-1);
}

.separator {
  margin: 16px 0;
}

.cardsWrapper {
  margin-top: 48px;
  display: flex;
  gap: 16px;

  @include media("<phone-lg") {
    flex-direction: column;
  }
}

.cardItem {
  //width: 416px;
  //height: 202px;
  flex: 0 1 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid var(--grey-2);
  border-radius: 4px;
  padding: 24px;

  @include media("<desktop-xlg") {
    width: 100%;
  }

  @include media("<tablet") {
    height: 128px;
  }

  @include media("<phone-lg") {
    height: 106px;
  }
}

.shadowText {
  font-size: 48px;
  line-height: 48px;

  @include media("<tablet") {
    font-size: 32px;
    line-height: 44px;
  }
}

.cardText {
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: var(--white);

  @include media("<tablet") {
    font-size: 14px;
    line-height: 22px;
  }
}
