:root {
  --gradient-background-grey: linear-gradient(180deg, rgba(62, 80, 81, 0.6) 0%, rgba(106, 135, 137, 0) 5.05%), linear-gradient(180deg, #6A8789 0%, #3E5051 100%);
  //--gradient-background-blue-dark: linear-gradient(48.79deg, #1692B5 -4.51%, #0D5FB3 60.17%, #0C539B 86.95%);
  // ----- //
  --main: #CDF676;
  --main-light: #E3FCAF;
  --main-dark: #99B955;
  --main-2: #87A449;
  --white: #ffffff;
  --white-10: #ffffff10;
  --font: #3A4F50;
  --grey-1: #A0BBBC;
  --grey-1-12: #A0BBBC1F;
  --grey-2: #688182;
  --grey-2-12: #6881821F;
  --grey-2-24: #6881823D;
  --grey-3: #496A75;
  --grey-4: #5F7C86;
  --bg: #E5EEEE;
  --light: #CEE0E0;
}
