@import "../../../../styles/media-mixin.scss";

.layoutSection {
  background-color: var(--bg);
}

.layoutSectionContent {
  display: flex;
  justify-content: space-between;
  padding-top: var(--global-vertical-padding);
  padding-bottom: var(--global-vertical-padding);

  @include media("<desktop-xlg") {
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
  }
}

.titleWrapper {
  flex: 1;
}

.sectionTitle {
  max-width: 524px;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: var(--font);

  @include media("<desktop-xlg") {
    max-width: 726px;
  }

  @include media("<phone-lg") {
    font-size: 24px;
  }
}

.contentWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.defaultText {
  font-size: 16px;
  line-height: 28px;
  color: var(--grey-2);

  @include media("<desktop-xlg") {
    max-width: 632px;
  }
}

.separator {
  padding: 0;
}

.warningWrapper {
  display: flex;
  align-items: center;
  padding: 20px 24px;
  background-color: var(--white);
  border-radius: 4px;
  gap: 24px;

  @include media("<phone-lg") {
    flex-direction: column;
    align-items: flex-start;
  }
}

.warningIconWrapper {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: radial-gradient(98.75% 98.75% at 90% 16.25%, #F9FFED 0%, #CDF676 73.2%, #5C820C 100%);
  box-shadow: 12px 8px 20px rgba(184, 245, 204, 0.24), inset -2px 2px 4px rgba(145, 255, 103, 0.22), inset 2px -2px 4px rgba(255, 255, 255, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
}

.warningText {
  font-weight: 700;
  text-transform: uppercase;
  color: var(--font);

  @include media("<desktop-xlg") {
    max-width: 532px;
  }
}

.boldText {
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: var(--font);

  @include media("<desktop-xlg") {
    max-width: 632px;
  }
}

.buttonsWrapper {
  margin-top: 8px;
  display: flex;
  gap: 16px;

  @include media("<phone-lg") {
    flex-direction: column;
  }
}
