@import "../../../../styles/media-mixin";

.section {
  background-size: cover;
  background-repeat: no-repeat;
}

.content {
  padding-top: var(--header-height);
  padding-bottom: 152px;

  @include media("<tablet") {
    padding-bottom: 128px;
  }

  @include media("<phone-lg") {
    padding-bottom: 60px;
  }
}

.container {
  display: grid;
  align-items: flex-end;
  grid-template-columns: auto 416px;
  grid-template-areas:
          '. contact'
          'title contact'
          'content contact';
  margin-top: 56px;

  @include media("<tablet-md") {
    grid-template-columns: auto 356px;
    align-items: unset;
    grid-template-areas:
          'title title'
          'content contact';
  }

  @include media("<tablet") {
    grid-template-columns: auto;
    grid-template-areas:
          'title'
          'content'
          'contact';
  }

  @include media("<tablet") {
    margin-top: 40px;
  }
}

.title {
  color: var(--white);
  grid-area: title;
  @include media("<tablet-md") {
    margin-bottom: 24px;
  }
}

.contentSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-area: content;
  margin-top: 16px;
  max-width: 576px;
  @include media("<tablet-md") {
    margin-top: 0;
  }
}

.description {
  font-weight: 400;
  margin-bottom: 24px;
  color: var(--grey-1);
}

.contactSection {
  grid-area: contact;
  flex-shrink: 0;

  @include media("<tablet") {
    margin-top: 40px;
  }
}
