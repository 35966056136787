@import "../../../../styles/media-mixin";

.fundingOptionsSectionWrapper {
  background-image: linear-gradient(180deg, #CEE0E0 0%, rgba(206, 224, 224, 0) 100%);
  background-color: var(--bg);
}

.sectionLayout {
  margin: 0 auto;
  max-width: var(--page-content-max-width);
  padding: var(--global-vertical-padding) var(--global-horizontal-padding);
  display: flex;
  justify-content: space-between;

  @include media("<desktop-xlg") {
    flex-direction: column;
    gap: 16px;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
}

.titleAndDescriptionWrapper {
  display: flex;
  flex-direction: column;

  @include media("<desktop-xlg") {
    flex-direction: row;
    align-items: center;
    gap: 40px;
  }

  @include media("<tablet") {
    flex-direction: column;
    align-items: unset;
    gap: unset;
  }
}

.sectionTitle {
  max-width: 490px;
}

.sectionDescription {
  max-width: 428px;
}


.currencyGridWrapper {
  flex: 0 1 50%;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(4, 1fr);

  @include media("<phone-lg") {
    grid-template-columns: repeat(2, 1fr);
  }
}
