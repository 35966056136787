@import "../../../styles/media-mixin";

.title {
  margin-bottom: 24px;
  font-weight: 700;
  color: var(--font);
}

.large {
  font-size: 64px;
  line-height: 80px;
  text-transform: uppercase;

  @include media("<tablet") {
    font-size: 48px;
    line-height: 64px;
  }

  @include media("<phone-lg") {
    max-width: 290px;
    text-align: center;
    font-size: 40px;
    line-height: 56px;
  }
}

.medium {
  font-size: 40px;
  line-height: 56px;

  @include media("<phone-lg") {
    font-size: 32px;
    line-height: 48px;
  }
}