@import "../../../../styles/media-mixin";

.row {
  min-height: 72px;
  background: var(--grey-4);
  border-radius: 4px;
  user-select: none;

  &.opened {

    .rowTitle {
      color: var(--main);
    }
  }

  &:not(:first-child) {
    margin-top: 8px;
  }

  @include media("<tablet") {
    min-height: 74px;
  }
}

.rowInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  cursor: pointer;

  @include media("<tablet") {
    padding: 24px;
  }
}

.rowTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  color: var(--bg);
  transition: 0.25s color ease-out;

  @include media("<tablet") {
    font-size: 16px;
    line-height: 24px;
  }
}

.rowIcon {
  transition: 0.25s transform ease-in-out, 0.25s color ease-out;
  color: var(--bg);

  &.opened {
    transform: scaleY(-1);
    color: var(--main);
  }
}

.rowContent {
  overflow: hidden;
  border-top: 1px solid transparent;
  transition: .15s max-height ease-in-out, .15s border-top-color ease-in-out;

  &:not(.opened) {
    max-height: 0 !important;
  }

  &.opened {
    border-top-color: var(--grey-1);
  }
}

.menu {
  display: flex;
  gap: 20%;
  padding: 24px 40px;

  @include media("<tablet") {
    padding: 24px;
  }

  @include media("<phone-lg") {
    flex-direction: column;
    gap: 16px;
  }
}

.menuSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  &:not(:first-child) {

    @include media("<phone-lg") {
      .menuTitle {
        margin-top: 16px;
      }
    }
  }
}

.menuTitle {
  height: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--grey-1);

  &:not(:first-child) {
    margin-top: 16px;
  }
}

.menuLink {
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: var(--white);
  text-transform: unset;
  justify-content: flex-start;

  &:hover {
    text-shadow: unset;
  }

  @include media("<tablet") {
    font-size: 16px;
    line-height: 24px;
  }
}
