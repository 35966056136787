@import "../../../../styles/media-mixin";

.platformChoiceSectionWrapper {
  background-color: var(--bg);
}

.sectionLayout {
  margin: 0 auto;
  max-width: var(--page-content-max-width);
  padding: calc(var(--global-vertical-padding) / 2) var(--global-horizontal-padding) 0;
  display: flex;
  flex-direction: column;
}

.separator {
  margin-top: 40px;
  margin-bottom: 80px;

  @include media("<phone-lg") {
    margin-bottom: 40px;
  }
}

.platformWrapper {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 48px;

  @include media("<tablet-md") {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0;
  }
}

.platformDescription {
  max-width: 476px;
  margin-bottom: 40px;

  @include media("<phone-lg") {
    margin-bottom: 24px;
  }
}

.platformInfoWrapper {
  max-width: 100%;
  padding-bottom: var(--global-vertical-padding);
  flex-shrink: 0;

  @include media("<tablet-md") {
    padding-bottom: 0;
  }
}

.platformActionsWrapper {
  display: flex;
  align-items: center;
  gap: 40px;

  @include media("<tablet-md") {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0;
  }
}

.platformInfo {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;

  @include media("<tablet-md") {
    top: 40px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: var(--white);
    border-radius: 12px;
    width: 376px;
    max-width: calc(100% - var(--global-horizontal-padding) * 2);
    z-index: 1;
  }
}

.platformLogoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  background-color: var(--white);
  border-radius: 50%;

  @include media("<tablet-md") {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--bg);
  }
}

.platformText {
  max-width: 182px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: var(--grey-2);

  @include media("<tablet-md") {
    max-width: unset;
    padding: 40px 0 24px;
  }

  @include media("<phone-lg") {
    max-width: 176px;
  }
}

.imageWrapper {
  overflow: hidden;

  img {
    width: 740px;
  }

  @include media("<desktop-xlg") {
    position: absolute;
    bottom: 0;
    right: calc(0px - var(--global-horizontal-padding));

    img {
      position: relative;
      right: -360px;
    }
  }

  @include media("<tablet-md") {
    position: static;

    img {
      max-width: 100%;
      position: static;
    }
  }

  @include media("<tablet") {
    width: calc(100% + var(--global-horizontal-padding) * 2);
  }
}