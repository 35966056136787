.section {
  background-size: cover;
}

.sectionContent {
  padding-bottom: 104px;
  padding-top: var(--header-height);
}

.title {
  margin-top: 20px;
  color: var(--white);
}
