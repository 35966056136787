@import "../../../../styles/media-mixin";

.headingSectionWrapper {
  position: relative;
  background-size: cover;
}

.sectionLayout {
  margin: 0 auto;
  max-width: var(--page-content-max-width);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  padding-left: var(--global-horizontal-padding);

  @include media("<tablet") {
    padding-right: var(--global-horizontal-padding);
  }
}

.homeSectionWrapper {
  padding-top: var(--header-height);
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.leftSideContentWrapper,
.rightSideContentWrapper {
  padding-top: 96px;

  @include media("<desktop-xlg") {
    padding-top: 64px;
  }

  @include media("<tablet-md") {
    padding-top: 80px;
  }

  @include media("<tablet") {
    padding-top: 64px;
  }

  @include media("<phone-lg") {
    padding-top: 56px;
  }
}

.leftSideContentWrapper {
  display: flex;
  flex-direction: column;

  @include media("<tablet") {
    align-items: center;
    text-align: center;
  }
}

.rightSideContentWrapper {
  max-width: calc(308px + var(--global-horizontal-padding));
  overflow: hidden;

  @include media("<tablet") {
    display: none;
  }
}

.rightSideTextWrapper {
  margin-right: var(--global-horizontal-padding);
  gap: 24px;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.rightSideCaption {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--grey-1);
}

.rightSideTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: var(--white);
}

.rightSideSubtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--grey-1);
}

.rightSideTextSvg {
  @include media("<desktop-xlg") {
    width: 432px;
    height: 132px;
  }

  @include media("<tablet-md") {
    width: 412px;
    height: 126px;
  }
}

.headingSectionCaption {
  gap: 16px;
  //max-width: 410px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--white);

  @include media("<phone-lg") {
    font-size: 14px;
  }
}

.headingSectionTitle {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  max-width: 760px;
  font-weight: 600;
  font-size: 64px;
  line-height: 72px;
  color: var(--white);

  span {
    color: var(--main);
    text-transform: uppercase;
    line-height: 96px;
  }

  @include media("<desktop-xlg") {
    font-size: 56px;
    line-height: 64px;
    //max-width: 700px;

    span {
      font-size: 64px;
      line-height: 80px;
    }
  }

  @include media("<tablet-md") {
    font-size: 48px;
    line-height: 56px;

    span {
      font-size: 56px;
      line-height: 64px;
    }
  }

  @include media("<phone-lg") {
    font-size: 32px;
    line-height: 56px;

    span {
      font-size: 48px;
      line-height: 56px;
    }
  }
}

.headingSectionSubtitle {
  margin-top: 24px;
  max-width: 560px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--grey-1);

  @include media("<tablet") {
    font-size: 16px;
  }

  @include media("<phone-lg") {
    font-size: 14px;
    max-width: 312px
  }

}

.mainSectionButtonsWrapper {
  margin-top: 48px;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  gap: 16px;

  @include media("<desktop-xlg") {
    margin-bottom: 40px;
  }

  @include media("<phone-lg") {
    flex-direction: column;
    width: 100%;
  }

}

.headingSectionButton {
  @include media("<phone-lg") {
    width: 100%;
  }
}

.cardWrapper {
  padding: 40px;
  margin-right: var(--global-horizontal-padding);
  background-image: linear-gradient(180deg, rgba(63, 81, 82, 0.21) 0%, rgba(255, 255, 255, 0) 21.81%);
  background-color: var(--white);
  border-radius: 24px 24px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  @include media("<tablet") {
    margin-right: 0;
  }

  @include media("<phone-lg") {
    align-items: center;
    text-align: center;
  }

}

.cardCaption {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: var(--grey-2);
  z-index: 1;
}

.cardTitle {
  margin-top: 16px;
  max-width: 524px;
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  color: var(--font);
  z-index: 1;

  @include media("<tablet") {
    max-width: 328px;
    font-size: 28px;
    line-height: 36px;
  }

  @include media("<phone-lg") {
    font-size: 24px;
    line-height: 32px;
  }
}

.cardSubtitle {
  margin-bottom: 16px;
  max-width: 448px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-2);
  z-index: 1;

  @include media("<tablet") {
    max-width: 368px;
  }
}

.cardButton {
  z-index: 1;
}

.imageWrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;

  @include media("<phone-lg") {
    display: none;
  }

  img {
    position: relative;
    width: auto;
    height: 900px;
    display: block;


    @include media("<laptop-hd") {
      right: -178px;
    }

    @include media("<desktop-xlg") {
      height: 814px;
      right: -346px;
    }

    @include media("<tablet-md") {
      height: 814px;
      right: -522px;
    }

    @include media("<tablet") {
      height: 664px;
      right: -404px;
    }
  }
}