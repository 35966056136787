@import "fonts.scss";
@import "colors.scss";
@import "variables.scss";
@import "z-index.scss";
@import "constants.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  min-width: 0;
  scroll-behavior: smooth;
}

body {
  cursor: default;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background: var(--grey-3);

  &::-webkit-scrollbar,
  ::-webkit-scrollbar {
    width: var(--scroll-width);
    height: var(--scroll-width);

    &-track {
      background: transparent;
    }

    &-thumb {
      background: var(--grey-1);
      border-radius: 4px;

      &:hover,
      &:active {
        background: var(--grey-1);
      }
    }
  }
}

img {
  display: block;
}

button,
input {
  font-family: inherit;
}

#root {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
