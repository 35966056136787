@import "../../../../styles/media-mixin.scss";

.sectionLayout {
  background-color: var(--bg);
}

.layoutSectionContent {
  padding-top: 64px;
}

.bottomSectionLayout {
  background-color: var(--bg);
}

.bottomSection {
  padding-top: 24px;
  display: flex;
  align-items: stretch;
  gap: 16px;

  @include media("<tablet-md") {
    flex-wrap: wrap;
  }

  @include media("<phone-lg") {
    flex-wrap: nowrap;
  }
}

.title {
  margin-bottom: 16px;
}

.subtitle {
  font-size: 20px;
  margin-top: 40px;
  //padding-bottom: 24px;
}

.text {
  margin-bottom: 40px;
  max-width: 632px;
  font-size: 16px;
  line-height: 28px;
  color: var(--grey-2);
}

.card {
  flex-grow: 1;
  width: min(308px, 100%);
  padding: 32px 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: var(--grey-2);
  background-color: var(--white);
  border-radius: 4px;

  @include media("<tablet") {
    width: min(248px, 100%);
  }

  @include media("<phone-lg") {
    min-width: 292px;
  }

  &:first-child {
    @include media("<phone-lg") {
      margin-left: 24px;
    }
  }

  &:last-child {
    @include media("<phone-lg") {
      margin-right: 24px;
    }
  }
}