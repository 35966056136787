@import "../../../../styles/media-mixin";

.section {
  padding: 64px 0;
  background-color: var(--bg);
}

.root {
  display: flex;
  flex-direction: column;
}

.rootInner {
  display: flex;
  justify-content: space-between;
  gap: 64px;

  @include media("<tablet-md") {
    flex-direction: column;
    justify-content: unset;
    gap: unset;
  }
}

.rootLeftBlock,
.rootRightBlock {
  display: flex;
  flex-direction: column;

  @include media("<tablet-md") {
    align-items: center;
  }
}

.title {
  margin-bottom: 32px;
  color: var(--font);
}

.subtitle {
  margin-bottom: 24px;
  color: var(--font);
  max-width: 632px;
}

.description {
  font-weight: 400;
  margin-bottom: 40px;
  color: var(--grey-2);
  max-width: 524px;
}

.title,
.subtitle,
.description {
  @include media("<tablet-md") {
    text-align: center;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;

  .button {
    flex: 1;

  }

  @include media("<phone-lg") {
    flex-direction: column;
    .button {
      flex: unset;
    }
  }
}
