.vertical {
  padding: 0 8px;
  height: 24px;

  .separator {
    width: 1px;
    height: 100%;
  }
}

.horizontal {
  padding: 8px 0;
  width: 100%;

  .separator {
    height: 1px;
  }
}

.separator {
  background: var(--bg);
}


