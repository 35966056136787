@import "../../../../styles/media-mixin";

.section {
  position: relative;
  margin-top: -72px;

  &:before {
    content: "";
    position: absolute;
    top: 72px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--bg);
  }
}

.table {
  width: calc(100% + 16px);
  border-spacing: 8px 0;
  margin-left: -8px;
  margin-right: -8px;

  @include media("<tablet") {
    padding-left: var(--global-horizontal-padding);
    padding-right: var(--global-horizontal-padding);
  }
}

.row {
  &:last-child {
    .cell {
      border-bottom: 0;
      border-radius: 0 0 4px 4px;
      overflow: hidden;
      padding: 0;

      &:not(.cellHeader):before {
        display: none;
      }
    }
  }

  &:nth-last-child(2) {
    .cell:not(.cellHeader):before {
      display: none;
    }
  }
}

.cell {
  padding: 16px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-2);

  @include media("<tablet-md") {
    padding: 8px;

  }
  @include media("<tablet") {
    min-width: 252px;
  }

  @include media("<phone-lg") {
    min-width: 230px;
  }

  &:first-child {
    text-align: left;
    padding: 6px 16px 6px 0;
    max-width: 216px;
    width: 216px;
    font-size: 14px;
    line-height: 22px;
    color: var(--main-gray);

    @include media("<desktop-xlg") {
      display: none;
    }
  }

  &:not(:first-child):not(.cellHeader) {
    background: var(--white);
    text-align: center;
  }

  &:not(.cellHeader) {
    border-bottom: 1px solid var(--bg);

    &:first-child {
      position: relative;
      border-bottom-color: transparent;
      color: var(--grey-2);

      &:before {
        position: absolute;
        content: "";
        bottom: -1px;
        left: 0;
        right: 8px;
        height: 1px;
        background: var(--grey-1);
      }
    }
  }
}

.cellHeader {
  position: relative;
  height: 72px;
  border-radius: 4px 4px 0 0;
  padding: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  overflow: hidden;
  color: var(--white);

  &:not(:first-child) {
    .cellInner {
      background-color: var(--grey-2);
    }
  }

  .cellInner {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding: 6px 16px;
  }

  &:first-child {
    text-transform: uppercase;


    .cellInner {
      color: var(--bg);
      justify-content: flex-start;
      padding-left: 0;
    }
  }
}

.cellCaption {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: var(--grey-1);
  margin-bottom: 8px;
  display: none;

  @include media("<desktop-xlg") {
    display: block;
  }
}

.cellDescription {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: var(--grey-1);
  margin-top: 8px;
}

.icon {
  flex-shrink: 0;
  margin-right: 20px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: radial-gradient(98.75% 98.75% at 90% 16.25%, #F9FFED 0%, #CDF676 73.2%, #5C820C 100%);
  box-shadow: 12px 8px 20px rgba(184, 245, 204, 0.24), inset -2px 2px 4px rgba(145, 255, 103, 0.22), inset 2px -2px 4px rgba(255, 255, 255, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  color: var(--blue-1);
  font-weight: 700;
  text-transform: unset;
  text-decoration: underline;
}

.button {
  width: 100%;
}