@import "../../../../styles/media-mixin";

.sectionLayout {
  background-color: var(--bg);
}

.sectionContent {
  padding-top: 64px;
  padding-bottom: 120px;
}

.box {
  background-color: var(--white);
  border-radius: 4px;
  padding: 24px;
  margin-left: 224px;

  @include media("<desktop-xlg") {
    margin-left: 0;
  }
}

.text {
  color: var(--grey-1);
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 18px;
}
