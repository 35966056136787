@import "../../../../styles/media-mixin";

.sectionLayout {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: var(--header-height);
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--bg);
  }
}

.sectionContent {
  padding-bottom: 80px;
}

.container {
  margin-top: -72px;

  @include media("<phone-lg") {
    margin-top: -42px;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @include media("<tablet-md") {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media("<phone-lg") {
    grid-template-columns: 1fr;
  }
}

.gridItem {
  background: var(--white);
  border-radius: 4px;
  padding: 32px 24px;
}

.itemTitle {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: var(--font);
}

.itemCaption {
  font-weight: 700;
  color: var(--font);
  margin-top: 24px;
  text-align: center;
}

.itemContent {
  color: var(--grey-2);
  margin-top: 24px;
  text-align: center;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 24px;

  @include media("<tablet") {
    & > * {
      flex: 1;
    }
  }

  @include media("<phone-lg") {
    flex-direction: column;
    & > * {
      flex: unset;
    }
  }
}
