.layoutSection {
  background-color: var(--bg);
}

.layoutSectionContent {
  padding-top: calc(var(--global-vertical-padding) / 2);
  padding-bottom: var(--global-vertical-padding);
}

.contentInnerWrapper {
  padding: 40px;
  border-radius: 4px;
  background-color: var(--white);
}

.boldText {
  margin-top: 24px;
  max-width: 478px;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: var(--grey-2);
}

.text {
  margin-top: 24px;
  max-width: 632px;
  font-size: 16px;
  line-height: 28px;
  color: var(--grey-2);
}
