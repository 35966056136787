.overlay {
  position: fixed;
  z-index: var(--sidebar-z-index);
  top: var(--header-height);
  right: 0;
  bottom: 0;
  background-color: rgba(22, 24, 28, 0.8);;

  &.active {
    left: 0;
  }
}

.panel {
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: hidden;
  position: absolute;
  //top: -1000px;
  top: 0;
  right: -1000px;
  //max-width: 334px;
  max-height: 100%;
  width: 100%;
  will-change: right, top, border-radius;
  transition: .25s right ease-in-out, .25s top ease-in-out, 0.5s border-radius ease-in-out;
  //filter: drop-shadow(4px 4px 12px rgba(0, 0, 0, 0.4));
  //border-bottom-left-radius: 200px;
  border-radius: 0 0 0 200px;

  &.active {
    right: 0;
    //top: var(--header-height);
    border-bottom-left-radius: 0;
  }
}
