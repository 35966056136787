@import "../../../styles/media-mixin";

.section {
  position: relative;
}

.content {
  position: relative;
  margin: 0 auto;
  max-width: var(--page-content-max-width);
  padding-left: var(--global-horizontal-padding);
  padding-right: var(--global-horizontal-padding);
  
  &.hideTabletPadding {
    @include media("<tablet") {
      padding-left: 0;
      padding-right: 0;
      overflow: scroll hidden;
      & {
        -ms-overflow-style: none;
        scrollbar-width: none;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &.hideMobilePadding {
    @include media("<phone-lg") {
      padding-left: 0;
      padding-right: 0;
      overflow: scroll hidden;
      & {
        -ms-overflow-style: none;
        scrollbar-width: none;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
