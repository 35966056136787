@import "../../../../styles/media-mixin";

.contactUsSectionWrapper {
  background-image: linear-gradient(180deg, rgba(58, 79, 80, 0) 0%, rgba(58, 79, 80, 0.24) 100%);
  background-color: var(--bg);
}

.sectionLayout {
  margin: 0 auto;
  max-width: var(--page-content-max-width);
  position: relative;
  padding: 0 var(--global-horizontal-padding);
}

.separator {
  margin-bottom: 80px;
}

.sectionLayoutInner {
  max-width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 132px;

  @include media("<tablet-md") {
    flex-direction: row;
    gap: 40px;
  }

  @include media("<tablet") {
    flex-direction: column;
    gap: 64px;
  }
}

.contactUsSectionLeft {
  display: flex;
  flex-direction: column;

  @include media("<tablet") {
    align-items: center;
    text-align: center;
  }
}

.contactUsSectionRight {
  flex-shrink: 0;
  position: relative;
  width: 308px;
  padding: 24px 24px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background: linear-gradient(180deg, rgba(58, 79, 80, 0) 87.03%, rgba(58, 79, 80, 0.16) 100%), #FFFFFF;
  border-radius: 12px 12px 0 0;
  text-align: center;

  @include media("<tablet") {
    width: 100%;
    padding-bottom: 40px;
  }
}

.contactUsSectionRightCaption {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: var(--grey-2);
}

.contactUsSectionRightTitle {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 26px;
  line-height: 40px;
  color: var(--font);

  @include media("<desktop-xlg") {
    max-width: 454px;
  }
}

.contactUsSectionDescription {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-2);
  margin-bottom: 16px;
}
