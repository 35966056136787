@import "../../../../../../styles/media-mixin.scss";

.tableWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 164px;
  border-top: 1px solid var(--grey-2);

  @include media("<tablet-md") {
    gap: 16px;
  }
}

.tableRowGridWrapper {
  padding: 24px;
  width: 100%;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-areas: "block1 block1  block2 block3 block4 block5";
  grid-gap: 24px;

  &:not(.heading) {
    padding: 24px;
    background-color: var(--grey-2);
  }

  &:not(.heading) :not(.rowTitle) .tableItemKey {
    display: none;

    @include media("<tablet-md") {
      display: block;
    }
  }

  @include media("<tablet-md") {
    grid-template-areas:
            "block1 block1 block1 block1"
            "block2 block3 block4 block5";
  }

  @include media("<phone-lg") {
    grid-template-areas:
            "block1 block1"
            "block2 block3"
            "block4 block5";
  }
}

.heading {
  margin-bottom: 4px;

  .tableItemKey {
    display: block !important;
  }

  .tableItemValue {
    display: none;
  }

  @include media("<tablet-md") {
    display: none;
  }
}

.tableItem {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.rowTitle {

  .tableItemKey {
    display: none;
  }

  .tableItemValue {
    font-weight: 700;
  }

  @include media("<tablet-md") {
    padding-bottom: 24px;
    border-bottom: 1px solid var(--grey-1);
  }

}

.tableItemKey {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--bg);
}

.tableItemValue {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  text-transform: uppercase;
  color: var(--white);
}

.block1 {
  grid-area: block1;
  min-width: 310px;

  @include media("<tablet-md") {
    min-width: 0;
  }
}

.block2 {
  grid-area: block2;
}

.block3 {
  grid-area: block3;
}

.block4 {
  grid-area: block4;
}

.block5 {
  grid-area: block5;
}
