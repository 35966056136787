@import "../../../../styles/media-mixin";

.section {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.deviceImage {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  display: block;
  max-width: 100%;
}

.sectionContent {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;
}


.topContentSection {
  padding-top: var(--header-height);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 20px;
  padding-bottom: 80px;

  @include media("<tablet") {
    padding-top: 12px;
    padding-bottom: 80px;
  }
}

.title {
  text-align: center;
  color: var(--white);
}

.subtitle {
  text-align: center;
  margin-bottom: 24px;
  margin-top: 80px;
  color: var(--white);
}

.description {
  text-align: center;
  max-width: 666px;
  color: var(--white);
}

.buttons {
  display: flex;
  gap: 16px;
  margin-top: 40px;

  @include media("<phone-lg") {
    flex-direction: column;
    width: 100%;
  }
}
