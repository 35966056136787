@import "../../../../../../styles/media-mixin";

.currencyItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 124px;
  background: var(--white);
  border-radius: 4px;

  @include media("<phone-lg") {
    height: 108px;
  }
}

.currencyTitle {
  margin-top: 12px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--font);
}

.currencyCaption {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: var(--grey-2);
}
