.menuItemContainer {
  position: relative;
  color: var(--grey-1);


  &:not(:last-child) {
    .subMenuWrapper {
      border-radius: 0 4px 4px 4px;
    }
  }

  &:hover {
    color: var(--main);

    .menuItemWrapper {
      background-color: var(--grey-4);
    }

    .icon {
      transform: scaleY(-1);
    }

    .subMenuWrapper {
      opacity: 1;
      transform: scaleY(1);
    }
  }

}

.menuItemWrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 24px;
  transition: 0.25s background-color ease-in-out, 0.25s color ease-in-out;
  height: 48px;
  padding: 0 16px;
  border-radius: 4px 4px 0 0;
  z-index: 1;
}

.menuItemTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: currentColor;
}

.icon {
  transition: 0.25s transform ease-in-out;
}

.subMenuWrapper {
  position: absolute;
  top: 48px;
  left: 0;
  background-color: var(--grey-4);
  opacity: 0;
  transform: scaleY(0);
  transition: 0.25s opacity ease-in-out;
  border-radius: 0 0 4px 4px;
  padding: 24px 16px 24px;
  z-index: 0;
}