@import "../../../../../../styles/media-mixin";

.tradeCurrenciesContainer {
  //width: 100%;
}

.currenciesTitle {
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--grey-1);
}

.tradeCurrenciesWrapper {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 4px;

  @include media("<tablet-md") {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media("<phone-lg") {
    grid-template-columns: repeat(2, 1fr);
  }
}

.currencyItem {
  flex-grow: 1;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: var(--font);
  background-color: var(--white);
  padding: 0 20px;
}
