@import "../../../../styles/media-mixin";

.section {
  padding-top: 40px;
  padding-bottom: 64px;
  background-color: var(--bg);
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
}

.grid {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(var(--grid-columns), 1fr);
  width: 100%;
  padding-top: 40px;

  @include media("<tablet-md") {
    grid-template-columns: repeat(6, 1fr);
    & > * {
      grid-column: span 2;
    }

    & > :nth-child(8):last-child {
      grid-column: span 3;
    }

    & > :nth-child(7):nth-last-child(-n+2) {
      grid-column: span 3;
    }

    & > :nth-child(7):last-child {
      grid-column: span 6;
    }
  }

  @include media("<tablet") {
    grid-template-columns: repeat(2, 1fr);
    & > * {
      grid-column: unset !important;
    }
  }
}

.gridItem {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  border-radius: 4px;
  padding: 24px;
  text-align: center;
  color: var(--grey-2);
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 52px;
  width: 100%;

  @include media("<phone-lg") {
    flex-direction: column;

    & > * {
      width: 100%;
    }
  }
}
