@import "../../../../styles/media-mixin.scss";

.layoutSectionWrapper {
  background-repeat: no-repeat;
  background-size: cover;
}

.layoutSectionContent {
  padding-top: var(--header-height);
  padding-bottom: 64px;
}

.sectionContentWrapper {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 16px;

  @include media("<tablet") {
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }
}

.contentWrapper {
  width: 100%;
}

.sectionTitle {
  color: var(--white);
  margin-bottom: 16px;
}

.sectionDescription {
  margin-bottom: 24px;
  max-width: 576px;
  font-size: 18px;
  line-height: 32px;
  color: var(--grey-1);

  @include media("<desktop-xlg") {
    max-width: 448px;
  }

  @include media("<tablet-md") {
    max-width: 352px;
  }

  @include media("<phone-lg") {
    font-size: 16px;
    line-height: 24px;
  }
}

.button {

  @include media("<phone-lg") {
    width: 100%;
  }
}

.contactUs {
  flex-shrink: 0;

  @include media("<tablet") {
    width: 100%;
  }
}
