.button {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid transparent;
  transition: 0.25s background-color ease-in-out, 0.25s box-shadow ease-in-out, 0.25s text-shadow ease-in-out, 0.25s color ease-in-out;
  flex-shrink: 0;
  white-space: nowrap;

  &:not(.disabled) {
    cursor: pointer;
  }

  &.active {
    cursor: default;
  }

  & span {
    margin-left: 16px;

    svg {
      display: block;
    }
  }
}


.xs {
  height: 24px;
  font-size: 14px;
  line-height: 22px;
}

.s {
  height: 32px;
}

.sm {
  height: 40px;
}

.m {
  height: 48px;
}

.l {
  height: 54px;
}

.xl {
  height: 64px;
}

.paddingS {
  padding: 0 16px;
}

.paddingM {
  padding: 0 24px;
}

.paddingL {
  padding: 0 32px;
}

.paddingXL {
  padding: 0 40px;
}

.gradient {
  position: relative;
  background-image: var(--gradient-background-grey);
  border: none;


  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: 0.25s background-color ease-in-out;
    border-radius: inherit;
    mix-blend-mode: overlay;
  }

  &:hover {

    &:after {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}

.default {
  background: var(--main);
  color: var(--font);

  &:not(.active) {
    &:hover {
      background: var(--main-light);
      box-shadow: inset -2px -2px 8px rgba(84, 97, 125, 0.1), inset 2px 4px 12px rgba(83, 129, 163, 0.12);
    }
  }
}

.outline {
  color: var(--font);
  background: transparent;
  border: 2px solid var(--grey-1);

  &:not(.active) {
    &:hover {
      background: var(--grey-2-12);
      box-shadow: inset -2px -2px 8px rgba(84, 97, 125, 0.1), inset 2px 4px 12px rgba(83, 129, 163, 0.12);
    }
  }
}

.outlineAccent {
  color: var(--main);
  background: transparent;
  border: 2px solid var(--grey-2);

  &:not(.active) {
    &:hover {
      background: var(--grey-2-12);
      box-shadow: inset -2px -2px 8px rgba(84, 97, 125, 0.1), inset 2px 4px 12px rgba(83, 129, 163, 0.12);
    }
  }
}

.outFilled {
  background: transparent;
  border: none;
  padding: 0;
  color: var(--font);


  &:not(.active) {
    &:hover {
      text-shadow: 0 0 24px currentColor;
    }
  }
}

.outFilledAccent {
  background: transparent;
  border: none;
  padding: 0;
  color: var(--main);

  &:not(.active) {
    &:hover {
      text-shadow: 0 0 24px currentColor;
    }
  }
}

.defaultBorder {
  border-radius: 4px;
}

.roundBorder {
  border-radius: 40px;
}

.upperCase {
  text-transform: uppercase;
}