@import "../../../../styles/media-mixin";

.section {
  background-image: linear-gradient(180deg, #A0BBBC 0%, rgba(160, 187, 188, 0) 100%);
  background-color: var(--bg);
}

.sectionContent {
  display: flex;
  flex-direction: column;
  padding-top: 64px;
  padding-bottom: 64px;
  align-items: center;
  text-align: center;
}

.benefits {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(var(--grid-columns), 1fr);
  padding-top: 40px;
  padding-bottom: 64px;
  width: 100%;
  @include media("<tablet") {
    grid-template-columns: repeat(2, 1fr);

    &:not(.even) {
      > .benefitsItem:first-child {
        grid-column: span 2;
      }
    }
  }
}

.benefitsItem {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  border-radius: 4px;
  padding: 24px;
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: var(--font);

  @include media("<phone-lg") {
    padding: 16px;
  }
}

.instructionsGrid {
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), 1fr);
  column-gap: 16px;
  width: 100%;
  padding-top: 64px;
}

.instructionItem {
  display: flex;
  justify-content: flex-start;
  background: var(--white);
  //border: 1px solid var(--grey-3);
  text-align: left;
  font-size: 16px;
  line-height: 28px;

  &.firstItem {
    background-color: var(--bg);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:not(.lastItem) {
    border-bottom: 0;
  }

  &.lastItem {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  padding: 16px 24px;

  &.header {
    padding: 24px;
  }

  .headerTitle {
    font-size: 32px;
    line-height: 44px;
    text-transform: unset;
    text-align: left;
    margin: unset;
    padding: 0;
    color: var(--font);

    @include media("<desktop-xlg") {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .counter {
    color: var(--grey-1);
    margin-right: 16px;
    font-weight: 700;
    font-size: 16px;
  }

  .instructionItemInner {
    display: flex;
    flex: 1;
    color: var(--font);

    svg {
      justify-self: flex-end;
      align-self: center;
      flex-shrink: 0;
      margin-left: auto;
      @include media("<desktop-xlg") {
        display: none;
      }

      @include media("<tablet") {
        display: block;
      }
    }
  }

  a {
    white-space: normal;
  }
}


.mobileGrid {
  padding-top: 64px;
  width: 100%;
}

.mobileColumn {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
