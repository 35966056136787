@import "../../../../styles/media-mixin";

.section {
  background-size: cover;
  background-repeat: no-repeat;
}

.content {
  padding-top: var(--header-height);
  padding-bottom: 120px;

  @include media("<tablet") {
    padding-bottom: 80px;
  }
}

.grid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
          'title image'
          'content image'
          '. image';

  margin-top: 56px;

  @include media("<desktop-xlg") {
    align-items: flex-start;
  }

  @include media("<tablet") {
    margin-top: 40px;
  }

  @include media("<tablet") {
    grid-template-columns: 1fr;
    grid-template-areas:
          'title'
          'image'
          'content';
  }
}

.gridTitle {
  grid-area: title;
  color: var(--white);
}

.gridContent {
  grid-area: content;
}

.caption {
  padding-top: 16px;
  color: var(--white);
  @include media("<tablet") {
    padding-top: 0;
  }
}

.description {
  padding-top: 24px;
  color: var(--grey-1);
  max-width: 520px;
}

.terms {
  margin-top: 16px;
  color: var(--main);
}

.buttons {
  display: flex;
  gap: 16px;
  margin-top: 32px;
  @include media("<tablet") {
    & > * {
      flex: 1
    }
  }

  @include media("<phone-lg") {
    flex-direction: column;
    & > * {
      flex: unset;
    }
  }
}

.button {
  min-width: 254px;
}

.gridImage {
  grid-area: image;

  img {
    max-width: 100%;
  }

  @include media("<tablet") {
    max-width: 428px;
  }
}