@import "../../../../styles/media-mixin.scss";

.layoutSectionWrapper {
  background-repeat: no-repeat;
  background-size: cover;
}

.layoutSectionContent {
  padding-bottom: var(--global-vertical-padding);
  padding-top: var(--header-height);
}

.sectionTitle {
  margin-bottom: 16px;
  color: var(--white);

  @include media("<desktop-xlg") {
    margin-bottom: 24px;
  }
}

.description {
  max-width: 530px;
  font-size: 18px;
  line-height: 32px;
  color: var(--grey-1);
  margin-bottom: 24px;
}

.text {
  margin-top: 24px;
  max-width: 530px;
  font-size: 14px;
  line-height: 22px;
  color: var(--grey-1);
}

.contentWrapper {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.contentTextWrapper {
  flex: 1;
}

.contentDaysWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include media("<tablet-md") {
    flex-direction: row;
    margin-bottom: 24px;
  }

  @include media("<tablet") {
    flex-direction: column;
  }
}

.dayItemWrapper {
  width: 100%;
  height: 154px;
  background: var(--grey-2-24);
  border-radius: 12px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  @include media("<desktop-xlg") {
    height: 124px;
  }

  @include media("<tablet-md") {
    height: 102px;
  }

  @include media("<tablet") {
    height: 90px;
  }

  @include media("<phone-lg") {
    height: 68px;
  }
}

.day {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-transform: uppercase;
  color: var(--white);

  @include media("<tablet-md") {
    font-size: 24px;
  }

  @include media("<phone-lg") {
    font-size: 18px;
    line-height: 24px;
  }
}

.dayItemWrapper .time {

  @include media("<tablet-md") {
    font-size: 40px;
    line-height: 56px;
  }

  @include media("<phone-lg") {
    font-size: 32px;
    line-height: 44px;
  }
}

.button {

  @include media("<phone-lg") {
    width: 100%;
  }
}
