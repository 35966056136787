@import "../../styles/media-mixin.scss";

.headerContainer {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: var(--header-height);
  z-index: 100;
  background: transparent;
  transition: 0.25s background-color ease-out;
}

.withBackground {
  background: var(--grey-3);
}

.headerWrapper {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: var(--page-content-max-width);
  padding: 12px var(--global-horizontal-padding);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -10px;
}

.headerMenu {
  display: flex;
  align-items: center;
}

.submenuWrapper {
  display: flex;
  gap: 64px;
}

.subMenuContent {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .headerLink {
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: var(--white);
    text-transform: unset;
    justify-content: flex-start;

    &:hover {
      text-shadow: unset;
    }
  }
}


.headerMenuTitle {
  margin-top: 12px;
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--grey-1);

  &:first-child {
    margin-top: 0;
  }
}


.actionsWrapper {
  display: flex;
  align-items: center;
  gap: 24px;

  @include media("<desktop-xlg") {
    margin-left: auto;
  }
}


