@import "../../../../../../styles/media-mixin";

.tradingConditionsWrapper {
  width: 100%;
  grid-gap: 24px 64px;
  display: grid;
  justify-content: flex-start;
  grid-auto-columns: auto;
  grid-template-columns: repeat(4, auto);

  @include media("<desktop-xlg") {
    grid-template-columns: repeat(2, auto);
  }

  @include media("<phone-lg") {
    justify-content: unset;
  }
}

.conditionItemTitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--grey-1);
  white-space: nowrap;

}

.conditionItemValue {
  margin-top: 8px;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--white);
  white-space: nowrap;
}
