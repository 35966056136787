@import "../../styles/media-mixin.scss";

.footerWrapper {
  background-color: var(--font);
}

.footerLayout {
  margin: 0 auto;
  max-width: var(--page-content-max-width);
  padding: 0 var(--global-horizontal-padding);
}

.footerSection {
  padding: 24px 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--white-10);
  }
}

.logoSection {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @include media("<tablet-md") {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
  }
}

.actionsWrapper {
  position: absolute;
  top: 32px;
  right: 0;
  display: flex;
  gap: 8px;

  @include media("<phone-lg") {
    position: relative;
    top: 0;
    width: 100%;
  }
}

.actionButton {

  &:first-child {

    @include media("<tablet-md") {
      display: none;
    }
  }

  @include media("<phone-lg") {
    flex: 1;
  }
}

.innerLinksWrapper {
  flex-shrink: 0;
  display: flex;
  gap: 40px;

  @include media("<desktop-xlg") {
    gap: 32px;
  }

  @include media("<phone-lg") {
    gap: 16px;
    flex-wrap: wrap;
  }
}

.innerLink {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: unset;

  @include media("<phone-lg") {
    flex-grow: 1;
    width: min(154px, 100%);
  }
}

.bottomSection {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media("<tablet-lg") {
    flex-direction: column;
    justify-content: center;
    gap: 12px;
  }
}

.logoWrapper {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  @include media("<phone-lg") {
    justify-content: center;
  }
}

.contactsInfo {
  max-width: 524px;
  margin-top: 24px;
  align-self: flex-start;

  @include media("<tablet-md") {
    max-width: max-content;
  }
}

.addressInfo {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  color: var(--grey-1);
}

.phoneNumber {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: var(--main);
}

.email {
  font-weight: 700;
  color: var(--main);
}

.warningInfo {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--grey-2);
}

.mailToButton {
  color: var(--grey-1);
}

.footerTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--grey-1);
}
