@import "media-mixin.scss";

:root {
  --header-height: 72px;
  --page-content-max-width: 1440px;
  --global-vertical-padding: 80px;
  --global-horizontal-padding: 80px;

  @include media("<desktop-lg") {
    --global-horizontal-padding: 24px;
  }
}